<template>
    <div class="document-preview">
        <div class="document-preview__header">
            <h3 class="v-h3 header__title">{{ attachment.file_name }}</h3>
            <a
                download
                :href="attachment.link"
                class="header__button"
                target="_blank"
            >
                <icon class="header__button-icon" width="12" height="14">
                    <rect
                        y="12"
                        width="12"
                        height="2"
                        rx="1"
                        fill="currentColor"
                    />
                    <path
                        d="M6.00347 0.000871965L6 8.00087M6 8.00087L8.0013 5.00174L4.0013 5L6 8.00087Z"
                        stroke="currentColor"
                        stroke-width="2"
                    />
                </icon>
            </a>
        </div>
        <template v-if="isExtensionSupport">
            <iframe
                @load="onLoad($event)"
                ref="iframeRef"
                :src="
                    `https://docs.google.com/viewer?url=${attachment.link}&embedded=true`
                "
                frameborder="0"
                class="document-preview__content"
            ></iframe>
            <loading-spinner class="loading-spinner" v-if="isLoading" />
        </template>
        <p v-else >Предпросмотр для данного файла недоступен</p>
    </div>
</template>

<script>
    import Icon from '@/components/icon/Icon'
    import LoadingSpinner from '@/components/LoadingSpinner'

    export default {
        name: 'DocumentPreviewModal',
        components: {
            Icon,
            LoadingSpinner
        },
        data() {
            return {
                isLoading: true,
                isExtensionSupport: false,
                timerId: '',
                extensions: [
                    'doc',
                    'docx',
                    'odt',
                    'pdf',
                    'xps',
                    'xls',
                    'xlsx',
                    'ppt',
                    'pptx',
                    'dfx',
                    'eps',
                    'psd',
                    'ps',
                    'svg',
                    'tif',
                    'ttf',
                    'txt'
                ]
            }
        },
        props: {
            attachment: {
                type: Object,
                required: true
            }
        },
        methods: {
            onLoad() {
                this.isLoading = false
                clearInterval(this.timerId)
            },
            reloadIFrame() {
                if (this.$refs.iframeRef) {
                    this.$refs.iframeRef.src = `https://docs.google.com/viewer?url=${this.attachment.link}&embedded=true`
                }
            }
        },
        mounted() {
            if (this.extensions.includes(this.attachment.extension)) {
                this.isExtensionSupport = true
                this.timerId = setInterval(() => this.reloadIFrame(), 5000)
            }
        },
        destroyed() {
            clearInterval(this.timerId)
        }
    }
</script>

<style lang="scss" scoped>
    @import '#sass/v-style';
    .document-preview {
        padding: 30px;
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &__content {
            width: 100%;
            height: 70vh;
            margin-top: 20px;
        }
        .header {
            &__button {
                padding: 0;
                background: transparent;
                color: #989cae;
                &-icon {
                    width: 20px;
                    height: 20px;
                }
                &:hover {
                    & .header__button-icon {
                        color: #000;
                    }
                }
            }
        }
        .loading-spinner {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
</style>
